import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useResponsive } from 'hooks';
import { Skeleton, Text } from 'ui';
var Cover = function (props) {
    var src = props.src, alt = props.alt, likes = props.likes;
    var isTabBig = useResponsive().isTabBig;
    return (_jsx("div", { style: {
            display: 'flex',
            flexGrow: 1,
            maxWidth: isTabBig ? 'unset' : 520,
            gridArea: 'cover',
            aspectRatio: 16 / 9,
            position: 'relative',
        }, children: _jsx("div", { style: {
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'start',
            }, children: src ? (_jsxs(_Fragment, { children: [_jsx("img", { src: src, alt: alt, loading: 'lazy', style: {
                            borderRadius: 8,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            objectPosition: 'center',
                        } }), likes >= 5 && (_jsx("div", { style: {
                            position: 'absolute',
                            bottom: 16,
                            right: 24,
                            backgroundColor: 'rgba(0,0,0, .75)',
                            padding: '2px 4px',
                            borderRadius: 4,
                        }, children: _jsxs(Text, { size: 'xs', weight: 'semibold', color: 'headline', children: [likes, " fans"] }) }))] })) : (_jsx(Skeleton, { style: {
                    height: '100%',
                    width: '100%',
                    borderRadius: 8,
                } })) }) }));
};
export default Cover;
