import { useQuery } from '@tanstack/react-query';
import { useApi, useSession } from 'hooks';
import useGetMe from './useGetMe';
var useGetRewards = function () {
    var apiFetch = useApi().apiFetch;
    var isLoggedIn = useSession().isLoggedIn;
    var me = useGetMe().data;
    var url = 'me/rewards';
    var queryFn = function () {
        return apiFetch({ url: url })
            .then(function (res) { return res.data; });
    };
    return useQuery({
        queryKey: ['rewards'],
        queryFn: queryFn,
        staleTime: 0,
        enabled: isLoggedIn && !!(me === null || me === void 0 ? void 0 : me.features.flixx),
    });
};
export default useGetRewards;
