var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { contentApi } from 'api';
import { ReactComponent as ChevronRightSvg } from 'assets/icons/chevron-right.svg';
import cardsImg from 'assets/tokenGating/covers.png';
import { ReactComponent as PlaySvg } from 'assets/tokenGating/play-flame.svg';
import { ReactComponent as TokenSvg } from 'assets/tokenGating/token-flame.svg';
import { ReactComponent as WalletSvg } from 'assets/tokenGating/wallet-flame.svg';
import { useExternalWallet, useResponsive, useSession } from 'hooks';
import { Button, Image, Pill, Seo, Text } from 'ui';
import { getContentUrl } from 'utils/contents';
import { getPoster } from 'utils/thumbnails';
import { formatDuration } from 'utils/duration';
import { richStringToNodes } from 'utils/strings';
import WalletConnection from './WalletConnection';
var TG_CAROUSEL_UUID = 'c93a76ce-88b4-4e6a-9e24-e6298bd8841d';
var FlameWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  height: 100px;\n  padding: 0 16px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 16px;\n\n  &::before {\n    content: \"\";\n    pointer-events: none;\n    position: absolute;\n    inset: 0;\n    border-radius: 16px; \n    padding: 2px; \n    background: linear-gradient(118.36deg, #C602E6 0%, #E6C100 100%); \n    mask: \n      linear-gradient(#fff 0 0) content-box, \n      linear-gradient(#fff 0 0);\n    -webkit-mask: \n      linear-gradient(#fff 0 0) content-box, \n      linear-gradient(#fff 0 0);\n    -webkit-mask-composite: xor;\n    mask-composite: exclude; \n  }\n"], ["\n  position: relative;\n  height: 100px;\n  padding: 0 16px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 16px;\n\n  &::before {\n    content: \"\";\n    pointer-events: none;\n    position: absolute;\n    inset: 0;\n    border-radius: 16px; \n    padding: 2px; \n    background: linear-gradient(118.36deg, #C602E6 0%, #E6C100 100%); \n    mask: \n      linear-gradient(#fff 0 0) content-box, \n      linear-gradient(#fff 0 0);\n    -webkit-mask: \n      linear-gradient(#fff 0 0) content-box, \n      linear-gradient(#fff 0 0);\n    -webkit-mask-composite: xor;\n    mask-composite: exclude; \n  }\n"])));
var TokenGating = function () {
    var _a;
    var t = useTranslation().t;
    var _b = useResponsive(), isTab = _b.isTab, isTabLand = _b.isTabLand, isMobile = _b.isMobile, isMobileBig = _b.isMobileBig;
    var isLoggedIn = useSession().isLoggedIn;
    var location = useLocation();
    var lastHash = useRef('');
    var carousel = contentApi.useGetCarousel(TG_CAROUSEL_UUID, { full: true }).data;
    var _c = useExternalWallet(), isConnected = _c.isConnected, isConnecting = _c.isConnecting, connect = _c.connect;
    useEffect(function () {
        if (location.hash) {
            lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
        }
        if (lastHash.current && document.getElementById(lastHash.current)) {
            setTimeout(function () {
                var _a;
                (_a = document
                    .getElementById(lastHash.current)) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth', block: 'start' });
                lastHash.current = '';
            }, 100);
        }
    }, [location]);
    return (_jsxs(_Fragment, { children: [_jsx(Seo, { title: 'Token Gating', description: t('tokenGating:seo_description') }), _jsxs("div", { children: [_jsxs("div", { style: {
                            background: 'radial-gradient(50% 115.49% at 50% 57.73%, #630A67 15%, #2B052D 100%)',
                            position: 'relative',
                            marginTop: -70,
                            paddingTop: isTab ? 90 : 50,
                            paddingBottom: isTab ? 0 : 50,
                        }, children: [_jsx("div", { style: {
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    height: isTab ? 380 : 180,
                                    width: '100%',
                                    background: 'linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0.97917) 4.7%, rgba(0, 0, 0, 0.95603) 8.9%, rgba(0, 0, 0, 0.9296) 12.8%, rgba(0, 0, 0, 0.898904) 16.56%, rgba(0, 0, 0, 0.862963) 20.37%, rgba(0, 0, 0, 0.8208) 24.4%, rgba(0, 0, 0, 0.771437) 28.83%, rgba(0, 0, 0, 0.713896) 33.84%, rgba(0, 0, 0, 0.6472) 39.6%, rgba(0, 0, 0, 0.57037) 46.3%, rgba(0, 0, 0, 0.48243) 54.1%, rgba(0, 0, 0, 0.3824) 63.2%, rgba(0, 0, 0, 0.269304) 73.76%, rgba(0, 0, 0, 0.142163) 85.97%, rgba(0, 0, 0, 0) 100%)',
                                    pointerEvents: 'none',
                                } }), _jsxs("div", { style: {
                                    maxWidth: 1250,
                                    width: '100%',
                                    margin: '0 auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flexDirection: isTab ? 'column' : 'row',
                                    gap: isTab ? 0 : 30,
                                    maxHeight: isTab ? 'unset' : 526,
                                    overflow: 'hidden',
                                }, children: [_jsx("div", { style: {
                                            display: 'grid',
                                            placeItems: 'center',
                                            maxWidth: isTab ? 450 : 550,
                                            aspectRatio: '343 / 324',
                                            width: '100%',
                                            height: '100%',
                                            background: "url(".concat(cardsImg, ")"),
                                            backgroundSize: '100%',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                        } }), _jsxs("div", { style: {
                                            position: 'relative',
                                            maxWidth: 539,
                                            width: '100%',
                                            padding: '0 32px',
                                            alignSelf: isTab ? 'start' : undefined,
                                        }, children: [_jsx(Text, { as: 'h1', size: isTab ? '3xl' : '5xl', weight: 600, style: {
                                                    background: 'linear-gradient(118.36deg, #DC02FF 0%, #FFD600 100%)',
                                                    WebkitBackgroundClip: 'text',
                                                    WebkitTextFillColor: 'transparent',
                                                    width: 'fit-content',
                                                }, children: "Token Gating" }), _jsx(Text, { size: isTab ? 'xl' : '3xl', weight: 600, color: 'headline', style: { marginTop: isTab ? 8 : 16 }, children: t('tokenGating:description') }), isLoggedIn && !isConnected && (_jsxs(_Fragment, { children: [_jsx(Text, { size: isTab ? 'base' : 'xl', weight: 600, color: 'headline', style: { marginTop: 16 }, children: t('tokenGating:description2') }), _jsx(Button, { variant: 'flame', size: 'large', style: { marginTop: 16 }, isLoading: isConnecting, onClick: function () { connect('landing'); }, children: t('tokenGating:cta') })] })), isConnected && (_jsxs(_Fragment, { children: [_jsx(WalletConnection, {}), _jsxs("a", { href: '#faqs', style: {
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 4,
                                                            marginTop: 16,
                                                        }, children: [_jsx(Text, { size: 'xl', weight: 600, color: 'headline', children: t('tokenGating:faqs.title') }), _jsx(ChevronRightSvg, { width: 16 })] })] }))] })] })] }), !isConnected && !isTabLand && (_jsx("div", { style: {
                            padding: '0 32px',
                            maxWidth: 1250,
                            margin: '24px auto 0',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: isTab ? 16 : 32,
                        }, children: _jsxs(FlameWrapper, { children: [_jsxs("div", { style: {
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '0 16px',
                                        gap: 16,
                                    }, children: [_jsx(Text, { as: 'span', size: '5xl', weight: 600, color: '#FBBF24', children: "1" }), _jsx(Text, { size: '2xl', weight: 600, color: 'headline', style: { whiteSpace: 'nowrap' }, children: t('tokenGating:step1') }), _jsx(TokenSvg, { style: {
                                                height: 60,
                                                width: 60,
                                                background: 'radial-gradient(50% 50% at 50% 50%, rgba(251, 191, 36, 0.2) 0%, rgba(252, 198, 20, 0) 100%)',
                                            } })] }), _jsx(ChevronRightSvg, { width: 24, height: 24 }), _jsxs("div", { style: {
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '0 16px',
                                        gap: 16,
                                    }, children: [_jsx(Text, { as: 'span', size: '5xl', weight: 600, color: '#FBBF24', children: "2" }), _jsx(Text, { size: '2xl', weight: 600, color: 'headline', style: { whiteSpace: 'nowrap' }, children: t('tokenGating:step2') }), _jsx(WalletSvg, { style: {
                                                height: 60,
                                                width: 60,
                                                background: 'radial-gradient(50% 50% at 50% 50%, rgba(251, 191, 36, 0.2) 0%, rgba(252, 198, 20, 0) 100%)',
                                            } })] }), _jsx(ChevronRightSvg, { width: 24, height: 24 }), _jsxs("div", { style: {
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '0 16px',
                                        gap: 16,
                                    }, children: [_jsx(Text, { as: 'span', size: '5xl', weight: 600, color: '#FBBF24', children: "3" }), _jsx(Text, { size: '2xl', weight: 600, color: 'headline', style: { whiteSpace: 'nowrap' }, children: t('tokenGating:step3') }), _jsx(PlaySvg, { style: {
                                                height: 60,
                                                width: 60,
                                                background: 'radial-gradient(50% 50% at 50% 50%, rgba(251, 191, 36, 0.2) 0%, rgba(252, 198, 20, 0) 100%)',
                                            } })] })] }) })), isConnected && (_jsx("div", { style: {
                            maxWidth: 1250,
                            margin: '0 auto',
                            padding: isTabLand
                                ? '32px 32px 100px'
                                : '64px 32px 150px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 64,
                        }, children: (_a = carousel === null || carousel === void 0 ? void 0 : carousel.slice(0, 3)) === null || _a === void 0 ? void 0 : _a.map(function (content, idx) { return (_jsxs("div", { style: {
                                display: 'flex',
                                gap: isMobileBig ? 16 : 64,
                                flexDirection: isMobileBig
                                    ? 'column'
                                    : idx % 2 === 0 ? 'row' : 'row-reverse',
                            }, children: [_jsx("div", { style: { width: '100%', maxWidth: 312, margin: '0 auto' }, children: _jsx(Image, { ratio: 'poster', src: getPoster({ media: content.media }), alt: content.title, title: content.title }) }), _jsxs("div", { style: { flexGrow: 1, flexShrink: 10 }, children: [_jsx(Text, { size: isMobileBig ? '3xl' : '4xl', weight: 600, color: 'headline', children: content.title }), _jsxs("div", { style: {
                                                display: 'flex',
                                                alignItems: (isTab && !isMobileBig) || isMobile
                                                    ? 'start'
                                                    : 'center',
                                                gap: (isTab && !isMobileBig) || isMobile
                                                    ? 8
                                                    : 16,
                                                marginTop: 8,
                                                flexDirection: (isTab && !isMobileBig) || isMobile
                                                    ? 'column'
                                                    : 'row',
                                            }, children: [_jsx(Pill, { value: content.contentType === 2
                                                        ? t('common:microseries', { count: 1 })
                                                        : t('common:shorts_one') }), _jsx("div", { style: { display: 'flex', alignItems: 'center', gap: 10 }, children: content.contentType !== 2 && (
                                                    // ? getReleaseYear(content, content.contentType)
                                                    _jsxs(_Fragment, { children: [_jsx(Text, { size: isMobileBig ? 'base' : 'xl', weight: 600, color: 'headline', children: new Date(content.releaseDate).getFullYear() }), _jsx(Text, { size: isMobileBig ? 'base' : 'xl', weight: 600, color: 'headline', children: "|" }), _jsx(Text, { size: isMobileBig ? 'base' : 'xl', weight: 600, color: 'headline', children: formatDuration(content.duration) }), _jsx(Text, { size: isMobileBig ? 'base' : 'xl', weight: 600, color: 'headline', children: "|" }), _jsx(Text, { size: isMobileBig ? 'base' : 'xl', weight: 600, color: 'headline', children: content.category })] })) })] }), _jsx(Text, { size: isMobileBig ? 'base' : 'xl', weight: 400, style: { marginTop: isMobileBig ? 16 : 32 }, color: '#D4D4D8', lineClamp: isMobile ? 6 : 7, children: richStringToNodes(content.body) }), _jsx(Button, { to: getContentUrl(content.contentType, content.uuid), variant: 'flame-inverted', size: 'large', weight: 'semibold', style: {
                                                width: isMobile ? '100%' : 160,
                                                marginTop: isMobileBig ? 16 : 32,
                                            }, children: t('common:showMore') })] })] }, content.uuid)); }) })), _jsxs("div", { id: 'faqs', style: {
                            maxWidth: 1250,
                            margin: '0 auto',
                            padding: isTabLand
                                ? '32px 32px 0'
                                : '64px 32px 0',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: isTab ? 16 : 32,
                        }, children: [_jsx(Text, { as: 'h2', size: isTab ? '2xl' : '4xl', weight: 600, color: 'headline', children: t('tokenGating:faqs.title') }), _jsx(Text, { size: isTab ? 'xl' : '2xl', weight: 600, color: '#FBBF24', children: t('tokenGating:faqs.question_1') }), _jsx(Text, { size: isTab ? 'base' : 'xl', weight: 600, color: 'headline', children: t('tokenGating:faqs.answer_1') }), _jsx(Text, { size: isTab ? 'xl' : '2xl', weight: 600, color: '#FBBF24', children: t('tokenGating:faqs.question_2') }), _jsx(Text, { size: isTab ? 'base' : 'xl', weight: 600, color: 'headline', children: t('tokenGating:faqs.answer_2') }), _jsx(Text, { size: isTab ? 'xl' : '2xl', weight: 600, color: '#FBBF24', children: t('tokenGating:faqs.question_3') }), _jsx(Text, { size: isTab ? 'base' : 'xl', weight: 600, color: 'headline', children: t('tokenGating:faqs.answer_3') }), _jsx(Text, { size: isTab ? 'xl' : '2xl', weight: 600, color: '#FBBF24', children: t('tokenGating:faqs.question_4') }), _jsx(Text, { size: isTab ? 'base' : 'xl', weight: 600, color: 'headline', children: t('tokenGating:faqs.answer_4') }), _jsx(Text, { size: isTab ? 'xl' : '2xl', weight: 600, color: '#FBBF24', children: t('tokenGating:faqs.question_5') }), _jsx(Text, { size: isTab ? 'base' : 'xl', weight: 600, color: 'headline', children: t('tokenGating:faqs.answer_5') }), _jsx(Text, { size: isTab ? 'xl' : '2xl', weight: 600, color: '#FBBF24', children: t('tokenGating:faqs.question_6') }), _jsx(Text, { size: isTab ? 'base' : 'xl', weight: 600, color: 'headline', children: t('tokenGating:faqs.answer_6') })] })] })] }));
};
export default TokenGating;
var templateObject_1;
