var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Lottie from 'lottie-react';
import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { contentApi } from 'api';
import { useBuyContent, useGetContent, } from 'api/content';
import { useGetBalance, useGetMe } from 'api/user';
import loaderJson from 'assets/lotties/player-loader.json';
import { useIsPurchased, useLocation, useOpen, useResponsive, useSession, } from 'hooks';
import useCarouselSlides from 'hooks/useCarouselSlides';
import i18n from 'i18n';
import { CardContentSuggestion, CarouselBase, CarouselButtons, ErrorCard, PlayerAdManager, Seo, Skeleton, Text } from 'ui';
import Anteroom from 'ui/Anteroom';
import Input from 'ui/Input';
import { getReleaseYear, getSubtitles } from 'utils/contents';
import { formatDuration } from 'utils/duration';
import { GAHandler } from 'utils/GA';
import { getCover, getMedia } from 'utils/thumbnails';
import { EpisodeInfo } from './EpisodeInfo';
import { SerieInfo } from './SerieInfo';
import { Episodes, Season } from './styles';
var PlayerLanding = lazy(function () { return import('ui/PlayerLanding'); });
var AnteroomSerie = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var _l = useParams(), serieUUID = _l.serieUUID, seasonOrder = _l.seasonOrder, episodeOrder = _l.episodeOrder;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var state = useLocation().state;
    var isLoggedIn = useSession().isLoggedIn;
    var _m = useResponsive(), isMobileBig = _m.isMobileBig, isTab = _m.isTab, isMobile = _m.isMobile;
    var _o = useIsPurchased(), isPurchased = _o.isPurchased, isPurchasesLoaded = _o.isPurchasesLoaded;
    var balance = useGetBalance().data;
    var me = useGetMe().data;
    var _p = useGetContent({ uuid: serieUUID, contentType: 'series' }), serie = _p.data, serieError = _p.error;
    var _q = useBuyContent(), buyContent = _q.buyContent, isBuying = _q.isLoading, isBought = _q.isSuccess, resetBuyContent = _q.reset;
    var adPlayer = useOpen();
    var contentPlayer = useOpen();
    var _r = state !== null && state !== void 0 ? state : {}, autoplay = _r.autoplay, forceClose = _r.forceClose;
    var _s = useState(), autoplayWaiting = _s[0], setAutoplayWaiting = _s[1];
    var similarsSlidesPerView = useCarouselSlides({
        config: {
            0: 1,
            420: 1.2,
            520: 1.5,
            700: 2,
            1024: 3,
            1400: 4,
            1750: 5,
            2200: 6,
        },
    });
    var serieTitle = (state === null || state === void 0 ? void 0 : state.title) || (serie === null || serie === void 0 ? void 0 : serie.title);
    var serieBody = (state === null || state === void 0 ? void 0 : state.body) || (serie === null || serie === void 0 ? void 0 : serie.body);
    var serieCover = (state === null || state === void 0 ? void 0 : state.cover) || getMedia({ media: serie === null || serie === void 0 ? void 0 : serie.media });
    var serieAuthor = (state === null || state === void 0 ? void 0 : state.author) || (serie === null || serie === void 0 ? void 0 : serie.author);
    var isEpisodeSelected = !!episodeOrder;
    var season = serie === null || serie === void 0 ? void 0 : serie.season.find(function (s) { return s.number === Number(seasonOrder); });
    var episode = isEpisodeSelected
        ? season === null || season === void 0 ? void 0 : season.content.find(function (ep) { return ep.order === Number(episodeOrder) - 1; })
        : season === null || season === void 0 ? void 0 : season.content.find(function (ep) { return ep.order === 0; });
    var currentEp = useRef();
    currentEp.current = episode;
    var nextEpisode = season === null || season === void 0 ? void 0 : season.content.find(function (ep) { return ep.order === (episode === null || episode === void 0 ? void 0 : episode.order) + 1; });
    var LastSeasonEpOrder = Math.max.apply(Math, season === null || season === void 0 ? void 0 : season.content.map(function (ep) { return ep.order; }));
    var LastSeasonEp = season === null || season === void 0 ? void 0 : season.content.find(function (ep) { return ep.order === LastSeasonEpOrder; });
    var nextSeason = serie === null || serie === void 0 ? void 0 : serie.season.find(function (s) { return s.number === Number(seasonOrder) + 1; });
    if (!nextEpisode) {
        nextEpisode = nextSeason === null || nextSeason === void 0 ? void 0 : nextSeason.content.find(function (ep) { return ep.order === 0; });
    }
    var lastSeriesEpisode;
    if (serie) {
        var lastSeasonNumber_1 = Math.max.apply(Math, serie.season.map(function (s) { return s.number; }));
        var lastSeason = serie.season.find(function (s) { return s.number === lastSeasonNumber_1; });
        var lastEpisodeOrder_1 = Math.max.apply(Math, lastSeason.content.map(function (c) { return c.order; }));
        lastSeriesEpisode = lastSeason.content.find(function (c) { return c.order === lastEpisodeOrder_1; });
    }
    var episodeUrl = "/".concat(i18n.language, "/series/").concat(serieUUID, "/").concat(seasonOrder, "/").concat((episode === null || episode === void 0 ? void 0 : episode.order) + 1);
    var nextEpisodeUrl = (episode === null || episode === void 0 ? void 0 : episode.uuid) === (LastSeasonEp === null || LastSeasonEp === void 0 ? void 0 : LastSeasonEp.uuid)
        ? "/".concat(i18n.language, "/series/").concat(serieUUID, "/").concat(nextSeason === null || nextSeason === void 0 ? void 0 : nextSeason.number, "/1")
        : "/".concat(i18n.language, "/series/").concat(serieUUID, "/").concat(seasonOrder, "/").concat((nextEpisode === null || nextEpisode === void 0 ? void 0 : nextEpisode.order) + 1);
    var _t = contentApi.useGetSimilars({
        uuid: episode === null || episode === void 0 ? void 0 : episode.uuid,
        enabled: !!episode && !!lastSeriesEpisode && episode.uuid === lastSeriesEpisode.uuid && contentPlayer.isOpen,
        purchaseMethods: 1,
    }), data = _t.data, isLoadingSimilars = _t.isLoading;
    var suggestions = (_a = data === null || data === void 0 ? void 0 : data.contents) !== null && _a !== void 0 ? _a : [];
    var handleSelectSeason = function (_a) {
        var value = _a.value;
        navigate("/series/".concat(serie === null || serie === void 0 ? void 0 : serie.uuid, "/").concat(value));
    };
    var handleCloseEpisode = function (_a) {
        var playedPercent = _a.playedPercent;
        contentPlayer.close();
        if (playedPercent >= 80) {
            if (nextEpisode) {
                navigate("./../".concat(Number(episode === null || episode === void 0 ? void 0 : episode.order) + 1));
            }
            else {
                navigate('./../');
            }
        }
        else {
            navigate('./');
        }
    };
    var handleBuyContent = function () {
        buyContent({
            contentUuid: currentEp.current.uuid,
            seriesUuid: serieUUID,
            price: currentEp.current.price,
        });
    };
    var endAd = function (newBalance) {
        var canPurchaseIt = parseFloat(episode.price) <= newBalance;
        if (canPurchaseIt) {
            contentPlayer.open();
            handleBuyContent();
        }
        else {
            GAHandler('reward_insufficient_to_buy', {
                uuid: episode.uuid,
                contentType: 'series',
            });
        }
        adPlayer.close();
    };
    var processWatchRequest = function () {
        resetBuyContent();
        if (isPurchased(currentEp.current.uuid)) {
            contentPlayer.open();
            return;
        }
        var canPurchaseIt = (!(me === null || me === void 0 ? void 0 : me.features.flixx)
            || parseFloat(currentEp.current.price) <= parseFloat(balance));
        if (canPurchaseIt) {
            contentPlayer.open();
            handleBuyContent();
            GAHandler('ui_watch_buy');
        }
        else {
            contentPlayer.close();
            adPlayer.open();
        }
    };
    var handleWatchNow = function () {
        currentEp.current = episode;
        processWatchRequest();
    };
    var handleWatchNextEp = function () {
        navigate(nextEpisodeUrl);
        currentEp.current = nextEpisode;
        processWatchRequest();
    };
    var handleWatchNowEpCard = function (ep) {
        GAHandler('watch_now_episode');
        currentEp.current = ep;
        processWatchRequest();
    };
    useEffect(function () {
        if (forceClose && contentPlayer.isOpen) {
            contentPlayer.close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceClose]);
    /**
     * Closes the player when autoplay is true
     * and user go from series to series
     */
    useEffect(function () {
        setAutoplayWaiting(autoplay);
        if (autoplay && contentPlayer.isOpen) {
            contentPlayer.close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoplay]);
    useEffect(function () {
        if (!autoplayWaiting || !episode)
            return;
        console.log('---------- UE 1');
        navigate(location.pathname, { state: { autoplay: false, forceClose: false }, replace: true });
        processWatchRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [episode]);
    useEffect(function () {
        if (!serie)
            return;
        if (episodeOrder || seasonOrder)
            return;
        var currentEpisode;
        var wholeSeriesPurchased = serie.season.every(function (s) { return s.content.every(function (c) { return isPurchased(c.uuid); }); });
        if (wholeSeriesPurchased) {
            // Without the timeout, after revisiting the navigate does not work
            setTimeout(function () {
                navigate("".concat(serie.season[0].number), { replace: true });
            }, 0);
            return;
        }
        var _loop_1 = function (season_1) {
            var allContentPurchased = season_1.content.every(function (content) { return isPurchased(content.uuid); });
            var noContentPurchased = season_1.content.every(function (content) { return !isPurchased(content.uuid); });
            var seasonNumber = season_1.number;
            if (noContentPurchased) {
                if (season_1.number === 1 || serie.season.length === 1) {
                    setTimeout(function () {
                        if (autoplay) {
                            navigate("".concat(seasonNumber, "/1"), { replace: true });
                        }
                        else {
                            navigate("".concat(seasonNumber), { replace: true });
                        }
                    }, 0);
                    return "break";
                }
            }
            if (!allContentPurchased) {
                currentEpisode = season_1.content.find(function (content) { return !isPurchased(content.uuid); });
                var episodeNumber_1 = currentEpisode.order + 1;
                setTimeout(function () {
                    navigate("".concat(seasonNumber, "/").concat(episodeNumber_1), { replace: true });
                }, 0);
                return "break";
            }
        };
        for (var _i = 0, _a = serie.season; _i < _a.length; _i++) {
            var season_1 = _a[_i];
            var state_1 = _loop_1(season_1);
            if (state_1 === "break")
                break;
        }
    }, [serie, episodeOrder, seasonOrder]);
    if ((serieError === null || serieError === void 0 ? void 0 : serieError.code) === 404) {
        return (_jsx("div", { style: { height: '80vh', display: 'grid' }, children: _jsx(ErrorCard, { title: 'Ups!', description: t('contentNotFound'), code: 'W3001' }) }));
    }
    if (serieError) {
        return (_jsx("div", { style: { height: '80vh', display: 'grid' }, children: _jsx(ErrorCard, { code: 'W3002', isBackHome: true }) }));
    }
    var abValue = localStorage.getItem('abValue');
    var isRewarded = Number(abValue) >= 50;
    if ((autoplay || autoplayWaiting) && !isRewarded) {
        return (_jsx("div", { style: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 999999999999999,
                backgroundColor: 'black',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pointerEvents: 'none',
            }, children: _jsx(Lottie, { animationData: loaderJson, loop: true, style: { width: isMobileBig ? 50 : 100 } }) }));
    }
    var titlePlayer = (_b = serie === null || serie === void 0 ? void 0 : serie.title) !== null && _b !== void 0 ? _b : '';
    var episodeTitle = (_d = (_c = currentEp.current) === null || _c === void 0 ? void 0 : _c.title) !== null && _d !== void 0 ? _d : '';
    var seasonEpisode = t('seasonEpisodeHyphen', {
        season: seasonOrder,
        episode: ((_e = currentEp.current) === null || _e === void 0 ? void 0 : _e.order) + 1,
    });
    var e = t('common:episodes_short') + String(((_f = currentEp.current) === null || _f === void 0 ? void 0 : _f.order) + 1);
    var s = t('common:seasons_short') + seasonOrder;
    var SeasonEpisode = function () { return (_jsxs("div", { style: { display: 'inline-flex', alignItems: 'center', gap: 2 }, children: [_jsx(Text, { color: 'headline', children: s }), _jsx(Text, { color: '#71717A', children: "-" }), _jsx(Text, { color: 'headline', children: e })] })); };
    var descriptionPlayer = (_jsx(Trans, { defaults: "<0></0>: ".concat(episodeTitle), components: [
            _jsx(SeasonEpisode, {}, 0),
        ] }));
    if (contentPlayer.isOpen) {
        return (_jsx(Suspense, { fallback: null, children: _jsx(PlayerLanding, { title: titlePlayer, author: serieAuthor === null || serieAuthor === void 0 ? void 0 : serieAuthor.nickname, description: descriptionPlayer, uuid: (_g = currentEp.current) === null || _g === void 0 ? void 0 : _g.uuid, video: __assign(__assign({}, currentEp.current), { seriesUuid: serieUUID }), creator: serieAuthor === null || serieAuthor === void 0 ? void 0 : serieAuthor.nickname, showCreatorPayment: isBought && (me === null || me === void 0 ? void 0 : me.features.flixx), onClose: handleCloseEpisode, nextEpisode: nextEpisode, onNextEpisode: handleWatchNextEp, suggestions: suggestions, userLiked: serie === null || serie === void 0 ? void 0 : serie.userLiked, isLoading: isBuying, onCreditsShow: ((_h = currentEp.current) === null || _h === void 0 ? void 0 : _h.uuid) === (lastSeriesEpisode === null || lastSeriesEpisode === void 0 ? void 0 : lastSeriesEpisode.uuid)
                    ? 'suggestions'
                    : 'nextEpisode' }, "".concat(serieUUID).concat((_j = currentEp.current) === null || _j === void 0 ? void 0 : _j.order)) }));
    }
    var seoTitle = serie
        ? isEpisodeSelected
            ? titlePlayer + ' - ' + seasonEpisode + ': ' + episodeOrder
            : titlePlayer
        : '';
    return (_jsxs(Anteroom.Root, { children: [_jsx(Seo, { title: seoTitle, description: serieBody }), _jsx(PlayerAdManager, { isOpen: adPlayer.isOpen, close: adPlayer.close, onReward: endAd }), _jsxs(Anteroom.Wrapper, { children: [_jsx(Anteroom.Cover, { src: isEpisodeSelected
                            ? getMedia({ media: episode === null || episode === void 0 ? void 0 : episode.media })
                            : serieCover, alt: isEpisodeSelected
                            ? episode === null || episode === void 0 ? void 0 : episode.title
                            : serieTitle, likes: serie === null || serie === void 0 ? void 0 : serie.likes }), _jsx(Anteroom.Info, { children: (isEpisodeSelected && episode) ? (_jsx(EpisodeInfo, { serie: serie.title, season: seasonOrder, episode: episode.order + 1, title: episode.title, duration: episode.duration, body: episode.body, audios: episode.audioLang, subtitles: getSubtitles(episode) })) : (_jsx(SerieInfo, { title: serieTitle, body: serieBody, tag: serie === null || serie === void 0 ? void 0 : serie.category.name, audioLang: serie === null || serie === void 0 ? void 0 : serie.audioLang, countSeasons: serie === null || serie === void 0 ? void 0 : serie.season.length, subtitles: getSubtitles(serie), releaseYear: getReleaseYear(serie, 2) })) }), _jsx(Anteroom.Actions, { uuid: episode === null || episode === void 0 ? void 0 : episode.uuid, author: serieAuthor, shareText: t('anteroom:series.share'), onWatchNow: function () { handleWatchNow(episode); }, showPrice: isLoggedIn && episode && !isPurchased(episode === null || episode === void 0 ? void 0 : episode.uuid), watchWithAd: Number(balance) < (episode === null || episode === void 0 ? void 0 : episode.price), contentPrice: episode === null || episode === void 0 ? void 0 : episode.price, shareUrl: "".concat(location.origin, "/").concat(i18n.language, "/series/").concat(serieUUID), watchNowDisabled: !serie, purchaseMethods: 1 })] }), _jsx(Anteroom.Carousel.Title, { children: serie ? (_jsx(_Fragment, { children: ((_k = serie.season) === null || _k === void 0 ? void 0 : _k.length) > 1 ? (_jsx(Input.Select, { onChange: handleSelectSeason, selected: {
                            label: "".concat(t('common:seasons_one'), " ").concat(season === null || season === void 0 ? void 0 : season.number, " (").concat(season === null || season === void 0 ? void 0 : season.content.length, " ").concat(t('common:episodes', { count: season === null || season === void 0 ? void 0 : season.content.length }), ")"),
                            value: season === null || season === void 0 ? void 0 : season.number,
                        }, options: serie.season.map(function (s) { return ({
                            label: "".concat(t('common:seasons_one'), " ").concat(s.number, " (").concat(s.content.length, " ").concat(t('common:episodes', { count: s.content.length }), ")"),
                            value: s.number,
                        }); }) })) : (_jsxs(Season, { children: ["".concat(t('common:seasons_one'), " ").concat(season === null || season === void 0 ? void 0 : season.number), _jsx(Episodes, { children: " (".concat(season === null || season === void 0 ? void 0 : season.content.length, " ").concat(t('common:episodes', { count: season === null || season === void 0 ? void 0 : season.content.length }), ")") })] })) })) : (_jsx(Skeleton, { style: { height: 30, width: 250, borderRadius: 4 } })) }), _jsx(CarouselBase, { slidesPerView: similarsSlidesPerView, defaultSlidePosition: episode === null || episode === void 0 ? void 0 : episode.order, isLoading: !(season === null || season === void 0 ? void 0 : season.content.length), offsetRight: isTab ? 16 : 40, offsetLeft: isMobile ? 26 : 45, spaceBetween: isMobile ? 14 : 16, rewind: true, renderNavigationBtns: function (props) { return _jsx(CarouselButtons, __assign({}, props, { isAbsolute: true })); }, children: season === null || season === void 0 ? void 0 : season.content.map(function (ep) {
                    var to = "series/".concat(serie.uuid, "/").concat(seasonOrder, "/").concat(ep.order + 1);
                    var cover = getCover({ media: ep.media });
                    var title = "".concat(ep.order + 1, ". ").concat(ep.title);
                    var duration = formatDuration(ep.duration);
                    return (_jsx(CardContentSuggestion, { onClickCTA: function () { handleWatchNowEpCard(ep); }, cover: cover, to: to, duration: duration, title: title, body: ep.body, price: ep.price, isPurchased: isPurchasesLoaded ? isPurchased(ep.uuid) : null, isSelected: ep.order === Number(episodeOrder - 1) }, ep.uuid));
                }) })] }));
};
export default AnteroomSerie;
