import { useTranslation } from 'react-i18next'

import { ReactComponent as OopsSVG } from 'assets/errors/oops.svg'
import { useResponsive } from 'hooks'
import { Button, Text } from 'ui'

import {
  Code,
  Container,
} from './styles'


const ErrorCard = (props) => {
  const { t } = useTranslation()

  const {
    icon: Icon = OopsSVG,
    title = t('error:default.title'),
    description = t('error:default.description'),
    isRefresh,
    isBackHome,
    isRadiusOnlyBottom,
    code = 'W1001',
    theme,
    children,
    stack,
  } = props


  const { isMobileBig } = useResponsive()

  const isCreators = theme === 'light'

  return (
    <Container
      $isCreators={isCreators}
      $isRadiusOnlyBottom={isRadiusOnlyBottom}
    >
      <Icon
        width={isMobileBig ? 90 : 180}
        height={isMobileBig ? 90 : 180}
      />

      <Text
        size={isMobileBig ? 14 : 16}
        style={{ marginTop: 14 }}
        color='headline'
        weight='semibold'
        theme={isCreators ? 'light' : 'dark'}
      >
        {title}
      </Text>

      <Text
        size={isMobileBig ? 12 : 14}
        style={{ marginTop: 10, maxWidth: '80%' }}
        theme={isCreators ? 'light' : 'dark'}
        align='center'
      >
        {description}
      </Text>

      {code && (
        <Code
          $isCreators={isCreators}
          style={{
            borderRadius: isMobileBig ? 4 : 6,
            padding: isMobileBig
              ? '4px 8px'
              : '6px 12px',
          }}
        >
          <Text
            size={isMobileBig ? 'xs' : 'sm'}
            weight='semibold'
          >
            {code}
          </Text>
        </Code>
      )}

      <div style={{ marginTop: 16 }}>
        {isRefresh && (
          <Button
            size={isMobileBig ? 'small' : 'medium'}
            variant='text'
            onClick={() => { window.location.reload() }}
          >
            <Text
              color='inherit'
              size={isMobileBig ? 'sm' : 'base'}
            >
              {t('retry')}
            </Text>
          </Button>
        )}

        {isBackHome && (
          <Button
            size={isMobileBig ? 'small' : 'medium'}
            variant='text'
            to='/'
          >
            <Text
              color='inherit'
              size={isMobileBig ? 'sm' : 'base'}
            >
              {t('goToHome')}
            </Text>
          </Button>
        )}
      </div>

      {children}

      {stack && (
        <div style={{
          height: 'fit-content',
          maxHeight: 300,
          width: '95%',
          maxWidth: 550,
          backgroundColor: '#111',
          padding: 14,
          borderRadius: 6,
          textWrap: 'wrap',
          overflowY: 'auto',
          overflowX: 'hidden',
          whiteSpace: 'pre',
          wordBreak: 'break-all',
          marginTop: 16,
        }}>
          <Text size='xs'>
            {stack}
          </Text>
        </div>
      )}
    </Container>
  )
}

export default ErrorCard
