import { useEffect, useState } from 'react'

import useApi from './useApi'
import useSession from './useSession'


const useDRM = (uuid, hasDRM) => {
  const { isLoggedIn } = useSession()

  const { apiFetch } = useApi()

  const [manifest, setManifest] = useState('')
  const [token, setToken] = useState('')

  const [error, setError] = useState()


  async function getManifest (contentUUID, typeDRM) {
    return await apiFetch({
      method: 'get',
      url: `/contents/${contentUUID}/drm/${typeDRM}`,
    })
  }

  const getManifestURL = async () => {
    try {
      const response = await getManifest(uuid, 'widevine')
      setManifest(response.data)
    } catch (e) {
      setError(e)
      console.log('error getManifest', e)
    }
  }


  async function getDRMToken (contentUUID, typeDRM) {
    return await apiFetch({
      url: '/drm/generatetoken',
      method: 'post',
      data: {
        contentUUID,
        type: typeDRM,
      },
    })
  }


  const getToken = async () => {
    try {
      const response = await getDRMToken(uuid, 'Widevine')
      setToken(response.data.token)
    } catch (e) {
      setError(e)
      console.log('error getToken', e)
    }
  }


  useEffect(() => {
    if (!isLoggedIn || !hasDRM) return

    getManifestURL()
    getToken()

    // eslint-disable-next-line
  }, [isLoggedIn, hasDRM]);


  return { manifest, token, error }
}

export default useDRM
