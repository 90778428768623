var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import videojs from 'video.js';
import { PLAYER_ID } from 'config/constants';
// const videojs = () => import('video.js')
import('videojs-contrib-eme');
// Detect the browser and set proper DRM type
function checkBrowser() {
    var agent = navigator.userAgent.toLowerCase();
    var name = navigator.appName;
    var browser;
    var drmType;
    if (name === 'Microsoft Internet Explorer' || agent.includes('trident') || agent.includes('edge/')) {
        browser = 'ie';
        if (name === 'Microsoft Internet Explorer') {
            // IE old version (IE 10 or Lower)
            agent = /msie ([0-9]{1,}[.0-9]{0,})/.exec(agent);
            // browser += parseInt(agent[1]);
        }
        else if (agent.includes('edge/')) { // Edge
            browser = 'Edge';
        }
        drmType = 'PlayReady';
    }
    else if (agent.includes('safari')) { // Chrome or Safari
        if (agent.includes('opr')) { // Opera
            browser = 'Opera';
            drmType = 'Widevine';
        }
        else if (agent.includes('whale')) { // Chrome
            browser = 'Whale';
            drmType = 'Widevine';
        }
        else if (agent.includes('edg/') || agent.includes('Edge/')) { // Chrome
            browser = 'Edge';
            drmType = 'PlayReady';
        }
        else if (agent.includes('chrome')) { // Chrome
            browser = 'Chrome';
            drmType = 'Widevine';
        }
        else { // Safari
            browser = 'Safari';
            drmType = 'FairPlay';
        }
    }
    else if (agent.includes('firefox')) { // Firefox
        browser = 'firefox';
        drmType = 'Widevine';
    }
    // The below three lines are for the sample code only. May need to be removed.
    var result = 'Running in ' + String(browser) + '. ' + String(drmType) + ' supported.';
    console.log(result);
    // document.getElementById("browserCheckResult").innerHTML = result;
    return { drmType: drmType, browser: browser, agent: agent, name: name };
}
var getPlayerConfig = function (props) {
    var type = props.type, manifest = props.manifest, licenseUri = props.licenseUri, token = props.token;
    var config;
    if (type === 'FairPlay') {
        // config = {
        //     src: hlsUri,
        //     type: 'application/x-mpegurl',
        //     keySystems: {
        //         'com.apple.fps.1_0': {
        //             getCertificate: function (emeOptions, callback) {
        //                 videojs.xhr({
        //                     url: fairplayCertUri,
        //                     method: 'GET',
        //                 }, function (err, response, responseBody) {
        //                     if (err) {
        //                         callback(err)
        //                         return
        //                     }
        //                     callback(null, base64DecodeUint8Array(responseBody));
        //                 })
        //             },
        //             getContentId: function (emeOptions, initData) {
        //                 const contentId = arrayToString(initData);
        //                 return contentId.substring(contentId.indexOf('skd://') + 6);
        //             },
        //             getLicense: function (emeOptions, contentId, keyMessage, callback) {
        //                 videojs.xhr({
        //                     url: licenseUri,
        //                     method: 'POST',
        //                     responseType: 'text',
        //                     body: 'spc=' + base64EncodeUint8Array(keyMessage),
        //                     headers: {
        //                         'Content-type': 'application/x-www-form-urlencoded',
        //                         'pallycon-customdata-v2': fairplayToken
        //                     }
        //                 }, function (err, response, responseBody) {
        //                     if (err) {
        //                         callback(err)
        //                         return
        //                     }
        //                     callback(null, base64DecodeUint8Array(responseBody))
        //                 })
        //             }
        //         }
        //     }
        // };
    }
    if (type === 'PlayReady') {
        config = {
            src: manifest,
            type: 'application/dash+xml',
        };
        config.keySystemOptions = [];
        config.keySystemOptions.push({
            name: 'com.microsoft.playready',
            options: {
                serverURL: licenseUri,
                httpRequestHeaders: {
                    'pallycon-customdata-v2': token,
                },
            },
        });
    }
    if (type === 'Widevine') {
        config = {
            src: manifest,
            type: 'application/dash+xml',
            keySystems: {
                'com.widevine.alpha': licenseUri,
            },
        };
    }
    if (config) {
        return config;
    }
    console.log('No DRM supported in this browser');
};
function processVideo(manifest, token) {
    return __awaiter(this, void 0, void 0, function () {
        var licenseUri, players, player, drmType;
        return __generator(this, function (_a) {
            licenseUri = "https://license-global.pallycon.com/ri/licenseManager.do?pallycon-customdata-v2=".concat(token);
            players = videojs.getAllPlayers();
            players.forEach(function (eachPlayer) { return eachPlayer.dispose(); });
            player = videojs(PLAYER_ID);
            // window.widevineToken = document.getElementById('token').value;
            window.widevineToken = token;
            drmType = checkBrowser().drmType;
            player.ready(function () {
                if (typeof player.eme === 'function') {
                    // initialize videojs-contrib-eme for DRM
                    player.eme();
                }
                var playerConfig = getPlayerConfig({
                    type: drmType,
                    licenseUri: licenseUri,
                    manifest: manifest,
                    token: token,
                });
                player.src(playerConfig);
                window.playerDRM = player;
            });
            return [2 /*return*/];
        });
    });
}
var useVideojsSamples = function () {
    return { processVideo: processVideo };
};
export default useVideojsSamples;
